import styles from './styles.module.css'
import ZipCodeTable from '../zip-code-table/ZipCodeTable'
import { LocationForm, ILocationFormProps } from '../location-form/LocationForm'
import { ITabulationAreasProps } from './LocationModal'
import SurveyUrlManager from '../survey-url-manager/SurveyUrlManager'

export interface ILocationCreateProps {
  locationFormInfo: ILocationFormProps
  tabulationAreasProps: ITabulationAreasProps
  refetch?: Function
}

export default function LocationCreate(props: ILocationCreateProps) {
  const { locationFormInfo, tabulationAreasProps } = props
  //temp logic to disable table functions when creating a new location as api response on create location is broken, so cant create zips directly after creating
  const isEditOrCreate = locationFormInfo.locationFormInfo.objectId
    ? false
    : true

  return (
    <div className={styles.mainContainer}>
      <LocationForm {...locationFormInfo} />
      <div className={styles.tableContainer}>
        <ZipCodeTable
          tabulationAreasProps={tabulationAreasProps}
          isEditOrCreate={isEditOrCreate}
          locationId={locationFormInfo.locationFormInfo.objectId}
          refetch={props.refetch}
        />
        <SurveyUrlManager
          locationFormInfo={locationFormInfo.locationFormInfo}
          setLocationFormInfo={locationFormInfo.setLocationFormInfo}
        />
      </div>
    </div>
  )
}
