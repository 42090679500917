export const convertMsToYMD = (ms: number) => {
  const dateObj = new Date(ms)
  const year = dateObj.getUTCFullYear()
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
  const day = String(dateObj.getUTCDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
export const convertMsDateToString = (ms: number) => {
  const dateObj = new Date(ms)
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
  const day = dateObj.getDate().toString().padStart(2, '0')
  const year = dateObj.getFullYear()
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

export function getWeekNumber(dateInMs: number) {
  const date = new Date(dateInMs)
  const januaryFirst = new Date(date.getFullYear(), 0, 1)
  const firstMonday = new Date(
    januaryFirst.getFullYear(),
    0,
    1 + ((8 - januaryFirst.getDay()) % 7),
  )
  const diffDays = Math.round(
    (date.getTime() - firstMonday.getTime()) / (1000 * 60 * 60 * 24),
  )
  const weekNumber = Math.floor(diffDays / 7) + 1

  return weekNumber
}

function getWeekDays(locale = 'en-US') {
  var baseDate = new Date(Date.UTC(2017, 0, 2)) // just a Monday

  return Array.from({ length: 7 }, () => {
    const weekday = baseDate.toLocaleDateString(locale, { weekday: 'long' })
    baseDate.setDate(baseDate.getDate() + 1)
    return weekday
  })
}

export function getDayName(date: number | Date | string, locale = 'en-US') {
  const utcDay = new Date(date).getUTCDay()
  return getWeekDays(locale)[utcDay]
}
export const reformatDate = (dateString: string) => {
  //reformats date in MM/DD/YY to YYYY-MM-DD format for date picker input in tech unique day settings table
  const [month, day, year] = dateString.split('/')
  const newDate = `20${year}-${month}-${day}`
  return newDate
}
export const getCurrentDate = () => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const day = currentDate.getDate()
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
}

export const getLocaleTimeString = (
  incomingDate: Date | string | number = new Date(),
  includeMeridiem = false,
) => {
  const date =
    incomingDate instanceof Date ? incomingDate : new Date(incomingDate)
  const hour = date.getHours()
  const minute = date.getMinutes()

  if (includeMeridiem) {
    const period = hour >= 12 ? 'PM' : 'AM'
    const formattedHours = hour % 12 || 12
    return `${formattedHours}:${minute.toString().padStart(2, '0')} ${period}`
  }

  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
}

export function getTimeWithOffset(timezoneOffset: string): string {
  const currentTime = new Date()

  // Local UTC offset in minutes
  const localOffsetMinutes = -currentTime.getTimezoneOffset() // Negative because getTimezoneOffset returns minutes behind UTC

  // Parse the provided timezone offset
  const isNegative = timezoneOffset.startsWith('-')
  const [hours, minutes] = timezoneOffset
    .replace('+', '') // Remove "+" for positive offsets
    .replace('-', '') // Remove "-" for negative offsets
    .split(':')
    .map((str) => parseInt(str, 10))
  const totalOffsetMinutes = (hours * 60 + minutes) * (isNegative ? -1 : 1)

  // Adjust the current time
  const adjustedTime = new Date(currentTime)
  adjustedTime.setMinutes(
    currentTime.getMinutes() - localOffsetMinutes + totalOffsetMinutes,
  )

  // Get adjusted hours and minutes
  const adjustedHours = adjustedTime.getHours()
  const adjustedMinutes = adjustedTime.getMinutes()

  // Determine AM or PM
  const period = adjustedHours >= 12 ? 'PM' : 'AM'

  // Convert to 12-hour format
  const formattedHours = adjustedHours % 12 || 12

  // Format the result
  return `${formattedHours}:${adjustedMinutes.toString().padStart(2, '0')} ${period}`
}

export const convertToMinutes = (timeString: string): number => {
  //takes in timeString in HH:MM AM|PM format and returns total minutes from start of day

  const [time, period] = timeString.split(' ')
  const [hours, minutes] = time.split(':').map(Number)

  let totalMinutes = hours * 60 + minutes

  // If it's a PM time and not 12 PM, we add 12 hours to the total
  if (period === 'PM' && hours !== 12) {
    totalMinutes += 12 * 60
  }

  // If it's an AM time and it's 12 AM, we subtract 12 hours from the total
  if (period === 'AM' && hours === 12) {
    totalMinutes -= 12 * 60
  }

  return totalMinutes
}

export function isToday(date: Date | string | number): boolean {
  const inputDate = new Date(date)

  if (isNaN(inputDate.getTime())) {
    return false
  }

  const today = new Date()

  return (
    inputDate.getFullYear() === today.getFullYear() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getDate() === today.getDate()
  )
}

export function formatTimeHMmA(date: Date): string {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const period = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 || 12
  const formattedMinutes = minutes.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes} ${period}`
}
