import { useEffect } from 'react'
import styles from './styles.module.css'
import LocationCreate from './LocationCreate'
import { ILocationFormProps } from '../location-form/LocationForm'
import { getLocationById } from '../../api'
import CircularProgress from '@mui/material/CircularProgress'
import useFetch from '../../../../../global/hooks/useFetchHook'
import { ITabulationAreasDataProps, ITabulationAreasProps } from './LocationModal'
import { ILocation } from '../../../../../../../app/entities/Location'

interface ILocationEditProps {
  formProps: ILocationFormProps
  locationId: string
  tabulationAreasProps: ITabulationAreasProps
}

export default function LocationEdit(props: ILocationEditProps) {
  const { formProps, locationId, tabulationAreasProps } = props

  const { data, isLoading, refetch } = useFetch<ILocation>(getLocationById(locationId))
  useEffect(() => {
    if (data) {
      formProps.setLocationFormInfo({
        ...formProps.locationFormInfo,
        ...data,
      })

      tabulationAreasProps.setTabulationAreasData((prevState: ITabulationAreasDataProps) => ({
        ...prevState,
        tabulationAreas: data.tabulationAreas,
      }))
    }
  }, [data])

  if (isLoading) {
    return (
      <div className={styles.progressContainer}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <LocationCreate
      locationFormInfo={formProps}
      tabulationAreasProps={tabulationAreasProps}
      refetch={refetch}
    />
  )
}
