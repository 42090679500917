import { MouseEventHandler, useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styles from './styles.module.css'
import PrimaryButton from '../../../../../global/components/buttons/primary-button/PrimaryButton'
import SecondaryButton from '../../../../../global/components/buttons/secondary-button/SecondaryButton'
import LocationEdit from './LocationEdit'
import LocationCreate from './LocationCreate'
import { fetchData } from '../../../../../global/utils/fetch'
import {
  createLocation,
  setTabulationArea,
  updateLocation,
} from '../../api'
import CircularProgress from '@mui/material/CircularProgress'
import { ITabulationArea } from '../../../../../../../app/entities/TabulationArea'
import { ILocation } from '../../../../../../../app/entities/Location'
import { ToastNotificationContext } from '../../../../../global/context/toast-context/ToastNotificationContext'
import { UserContext } from '../../../../../base/components/base-container/BaseContainer'
import { PERMISSIONS } from '../../../../../global/constants/permissions'
import hasPermission from '../../../../../global/utils/user/has-permission'

interface ILocationModalProps {
  isOpen: boolean
  handleClosed: MouseEventHandler
  locationId?: string
}

export interface ITabulationAreasDataProps {
  tabulationAreas: {
    stateAbbr: string
    zipCode: string
    objectId: string
    status: string
  }[]
  updated: {
    stateAbbr: string
    zipCode: string
    objectId: string
    status: string
  }[]
  created: {
    stateAbbr: string
    zipCode: string
    objectId: string
    status: string
  }[]
  deleted: {
    objectId: string
  }[]
  bulkCsv: []
}

export interface ITabulationAreasProps {
  tabulationAreasData: ITabulationAreasDataProps
  setTabulationAreasData: Function
}

const DEFAULT_LOCATION_FORM_INFO: Partial<ILocation> = {
  name: '',
  addressStreet: '',
  addressCity: '',
  stateAbbr: '',
  zipCode: '',
  workDayFrom: '',
  workDayTo: '',
  lunchFrom: '',
  lunchTo: '',
  usingTolls: false,
  productionMode: false,
  active: true,
  standbyQueueActive: false,
  travelTimeMax: 60,
  costCenter: 0,
}

export function LocationModal(props: ILocationModalProps) {
  const { user } = useContext(UserContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)
  const [isLoading, setIsLoading] = useState(false)
  const [locationFormInfo, setLocationFormInfo] = useState<Partial<ILocation>>({
    ...DEFAULT_LOCATION_FORM_INFO,
  })
  const [tabulationAreasData, setTabulationAreasData] =
    useState<ITabulationAreasDataProps>({
      tabulationAreas: [],
      updated: [],
      created: [],
      deleted: [],
      bulkCsv: [],
    })
  function handleCloseModal(e: any) {
    setLocationFormInfo({ ...DEFAULT_LOCATION_FORM_INFO })
    setTabulationAreasData({
      tabulationAreas: [],
      updated: [],
      created: [],
      deleted: [],
      bulkCsv: [],
    })
    props.handleClosed(e)
  }

  async function handleTabulationAreaApi(locationId: string | undefined) {
    const createdPromise =
      tabulationAreasData.created.length > 0
        ? tabulationAreasData.created.map((created: any) => {
            return fetchData<ITabulationArea | undefined>(
              setTabulationArea(locationId, created),
            )
          })
        : []
    const updatedPromise =
      tabulationAreasData.updated.length > 0
        ? tabulationAreasData.updated.map((updated: any) => {
            return fetchData<ITabulationArea | undefined>(
              setTabulationArea(locationId, updated),
            )
          })
        : []

    return Promise.all([createdPromise, updatedPromise])
  }
  //TODO revisit error handling when create location api is fixed
  async function handleCreateLocation(e: any) {
    try {
      setIsLoading(true)
      const createLocationResponse = await fetchData<ILocation>(
        createLocation(locationFormInfo),
      )
      //currently the response comes back as error so I cant use the regular responses objectId for subsequent calls
      // await handleTabulationAreaApi(createLocationResponse.objectId)
      handleCloseModal(e)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Successfully created location!',
        severity: 'success',
      })
    } catch (error) {
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error creating location.',
        severity: 'error',
      })

      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }
  async function handleUpdateLocation(e: any) {
    try {
      setIsLoading(true)
      await fetchData<ILocation>(updateLocation(locationFormInfo))
      await handleTabulationAreaApi(props.locationId)
      handleCloseModal(e)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'Successfully updated location!',
        severity: 'success',
      })
    } catch (error) {
      console.error(error)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        message: 'There has been an error updating location.',
        severity: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  function toggleActivateDeactivate() {
    setLocationFormInfo((prevState) => ({
      ...prevState,
      active: !prevState.active,
    }))
  }

  const activateAndDeactivateBtnText = locationFormInfo.active
    ? 'Deactivate'
    : 'Activate'

  const createLocationBtnDisabledLogic =
    !locationFormInfo.name ||
    !locationFormInfo.addressCity ||
    !locationFormInfo.addressStreet ||
    !locationFormInfo.stateAbbr ||
    !locationFormInfo.zipCode ||
    !locationFormInfo.workDayFrom ||
    !locationFormInfo.workDayTo ||
    // || !locationFormInfo.lunchFrom
    // || !locationFormInfo.lunchTo
    !locationFormInfo.lunchDuration

  const isDeactivateButtonDisabled = !hasPermission(user).edit(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.DEACTIVATE_BUTTON.NAME,
  )
  const isDeactivateButtonViewable =
    Boolean(props.locationId) &&
    hasPermission(user).view(
      PERMISSIONS.LOCATIONS.CATEGORY,
      PERMISSIONS.LOCATIONS.ENTRIES.DEACTIVATE_BUTTON.NAME,
    )

  const locationSettingsPermissionKeys = Object.keys(
    PERMISSIONS.LOCATIONS.ENTRIES,
  )

  const [canUserEditLocation, setCanUserEditLocation] = useState(
    checkCanUserEditLocation(),
  )
  function checkCanUserEditLocation() {
    return (
      !isLoading &&
      locationSettingsPermissionKeys.some((key) => {
        return hasPermission(user).edit(
          PERMISSIONS.LOCATIONS.CATEGORY,
          PERMISSIONS.LOCATIONS.ENTRIES[key].NAME,
        )
      })
    )
  }
  useEffect(() => {
    setCanUserEditLocation(checkCanUserEditLocation())
  }, [user?.role?.permissions])

  return (
    <div>
      <Modal
        open={props.isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={styles.locationModal}>
          <>
            <div className={styles.locationModalHeader}>
              <div className={`${styles.locationModalPageTitle} font--bold`}>
                {props.locationId ? 'Edit Location' : 'Create Location'}
              </div>
              <div className={styles.locationModalHeaderButtons}>
                <SecondaryButton
                  buttonName='Cancel'
                  onClick={handleCloseModal}
                />
                {isDeactivateButtonViewable && (
                  <SecondaryButton
                    buttonName={activateAndDeactivateBtnText}
                    onClick={toggleActivateDeactivate}
                    disabled={isDeactivateButtonDisabled}
                  />
                )}
                {props.locationId ? (
                  <PrimaryButton
                    buttonName='Save Location'
                    disabled={!canUserEditLocation}
                    onClick={handleUpdateLocation}
                  />
                ) : (
                  <PrimaryButton
                    buttonName='Create Location'
                    disabled={
                      !canUserEditLocation || createLocationBtnDisabledLogic
                    }
                    onClick={handleCreateLocation}
                  />
                )}
              </div>
            </div>
            {isLoading && (
              <div className={styles.progressContainer}>
                <CircularProgress />
              </div>
            )}
            {props.locationId && !isLoading && (
              <LocationEdit
                formProps={{ locationFormInfo, setLocationFormInfo }}
                tabulationAreasProps={{
                  tabulationAreasData,
                  setTabulationAreasData,
                }}
                locationId={props.locationId}
              />
            )}
            {!props.locationId && !isLoading && (
              <LocationCreate
                tabulationAreasProps={{
                  tabulationAreasData,
                  setTabulationAreasData,
                }}
                locationFormInfo={{ locationFormInfo, setLocationFormInfo }}
              />
            )}
          </>
        </Box>
      </Modal>
    </div>
  )
}
